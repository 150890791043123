import { useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../_utils/contextProvider";
import { Services } from "../_utils/services";
import { Button, Card, Divider, Flex, Layout, Space, Typography } from "antd";
import { ArrowLeftOutlined, CheckCircleTwoTone, DownloadOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import generatePDF from "react-to-pdf";

const layoutStyle = {
    overflow: 'hidden',
    width: '100%',
    // backgroundColor: '#fff'
};
const contentStyle: React.CSSProperties = {
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#fff'
};

const Result = () => {
    const { id } = useParams();
    const targetRef: any = useRef();
    // const { toPDF, targetRef } = usePDF({filename: 'Result.pdf'});
    const { scoreResult } = useGlobalContext();
    const [template, setTemplate] = useState<any>(null);
    const [overallSummary, setOverallSummary] = useState<any>(null);
    const [categorySummary, setCategorySummary] = useState<any>(null);
    const navigate = useNavigate();

    const _extractGradeSummary = (results: any) => {
        let overall = Services.getGradeSummaryFromScore(scoreResult.totalScore, results.overall);
        if (overall) {
            setOverallSummary(overall);
            if (results.overall.title) results.overall.title = results.overall.title.replace('--grade--', overall.id.toUpperCase());
            let description: any = [];
            results.description.forEach((d: string) => {
                description.push(d.replace('--grade--', overall.id.toUpperCase()));
            });
            results.description = description;
            setTemplate(results);
        }
        if (results.sections) {
            let categories: any = {};
            results.sections.forEach((section: any) => {
                let category = Services.getGradeSummaryFromScore(scoreResult.categories[section.title], section);
                if (category) {
                    categories[section.title] = category;
                }
            });
            setCategorySummary(categories);
        }
    }

    const _getResultsTemplate = (id: string) => {
        Services.getFile(`answers_${id}`).then((data: any) => {
            _extractGradeSummary(data.results);
        })
    }

    useEffect(() => {
        if (scoreResult)
            _getResultsTemplate(scoreResult.quotient as string);
    }, [scoreResult]);

    useEffect(() => {
        const confirmationMessage = 'Results will be lost if you leave this page! Are you sure?';
    
        const handleBeforeUnload = (event: any) => {
            const e = event || window.event;
            e.preventDefault();
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const _goBack = () => {
        navigate(`/quotient/${id}`);
    }
    const _gotolink = (link: string) => () => {
        window.open(`${link}`, '_blank');
    }
    const _generatePDF = () => {
        generatePDF(targetRef, {filename: `${id}_Result.pdf`});
    }

    return (
        <Layout style={layoutStyle} className="section">

            <Flex align="baseline" justify="center" style={{margin: '1em 0'}}>
                <Space>
                    <Button type="default" className="buttonBaseStyle" size="large" onClick={_goBack}>
                        <ArrowLeftOutlined /> Questionaire
                    </Button>
                    <Button type="primary" className="buttonBaseStyle"  size="large" onClick={() => _generatePDF()}>
                        <DownloadOutlined />Download Results
                    </Button>
                </Space>

            </Flex>

            {
                template &&
                    <div  style={{overflow: 'auto'}}>
                    <Content ref={targetRef} style={contentStyle} className="appPadding">
                        <div className="resultsLogowrap">
                            <img src="/logo.png" alt="logo" />
                            <div className="resultsLogowrap_text">
                                <Typography.Title level={3} className="sitelink" onClick={_gotolink('https://kanishkabhattacharya.com')}>{'kanishkabhattacharya.com'}</Typography.Title>
                                <div style={{margin: '2em auto', width: '100px', border: '2px solid #b5ea43'}}></div>
                                <Typography.Title level={4} className="subheader" >{id === 'EQ' ? `Emotional Quotient Result` : `Happiness Quotient Result`}</Typography.Title>
                            </div>
                        </div>

                        <Typography.Title style={{margin: '1em 0'}} level={3}>{template.summary} <GradeElement grade={overallSummary.id.toLowerCase()}/></Typography.Title>
                        {
                            template.description.map((d: string, idx: number) => (
                                <Typography.Title key={idx} level={5} style={{display: 'block', margin: '1em 0'}}>{d}</Typography.Title>
                            ))
                        }
                        {
                            categorySummary &&
                            Object.keys(categorySummary).map((key: string, idx: number) => (
                                <div key={idx}>
                                    <Typography.Title level={5}><Space><CheckCircleTwoTone />{key.toUpperCase()}: <GradeElement grade={categorySummary[key].id.toLowerCase()} /></Space></Typography.Title>
                                    <Typography.Text style={{display: 'block', margin: '1em 0'}}>{categorySummary[key].result.description}</Typography.Text>
                                </div>
                            ))
                        }
                        <br/>
                        { template.overall.title && <Typography.Title level={5} style={{textAlign:'center'}}>
                                {template.overall.title}
                            </Typography.Title>
                        }
                        {
                            overallSummary && 
                            <Card style={{backgroundColor: '#eff7ff', margin: '2em 0', borderRadius: 0, padding: '1vw'}}>
                                <Typography.Title level={5}>{overallSummary.result.description}</Typography.Title>
                                <Typography.Title level={5}>{overallSummary.result.overall}</Typography.Title>
                                    {
                                        overallSummary.result.traits.map((t: any, idx: any) => (
                                            <div key={idx}>
                                                <Typography.Title level={5}><Space><CheckCircleTwoTone />{t.trait}</Space></Typography.Title>
                                                <Typography.Text >{t.description}</Typography.Text>
                                            </div>
                                        ))
                                    }
                            </Card>
                        }
                        <Typography.Title level={5} style={{padding:'2em 0'}}><span style={{color: 'red'}}>IMPORTANT NOTE:</span> {template.note}</Typography.Title>
                    </Content>
                    </div>
            }
            
        </Layout>
    );
}

export default Result;


const GradeElement = ({grade}: any) => {
    const colors: any = {
        'low': 'red',
        'medium': 'orange',
        'high': '#b5e43d',
        'very high': '#4ceb4c'
    }
    return (
        <span style={{color: colors[grade]}}>{grade.toUpperCase()}</span>
    )
}