import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

const GlobalContext = createContext<any>(null);
export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalContextProvider = ({ children }: any) => {
    const [scoreResult, setResult] = useState<{quotient: string, totalScore: number, categories: {[key: string]: number}} | null>(null);

    const calculateAndShowResults = (id: string, formData: {[key: number]: string}, questions: any, navigate: any) => {
        let total = 0;
        for (let key in formData) {
            total += parseInt(formData[key]);
        }
        let categories: {[key: string]: number} = {};
        questions.forEach((q: any, idx: number) => {
            categories[q.category] = categories[q.category] ? categories[q.category] + parseInt(formData[idx]) : parseInt(formData[idx]);
        });
        setResult({'totalScore': total, 'categories': categories, 'quotient': id});
        console.log(total);
        console.log(categories);
        navigate(`/results/${id}`);
    };

    return (
        <GlobalContext.Provider value={{ scoreResult, calculateAndShowResults }}>
            {children}
        </GlobalContext.Provider>
    );
};
