import { useContext } from "react";
import { Navbar } from "../components";
import { Outlet } from "react-router-dom";


const Home = () => {

    return (
        <div className="mainContainer">
            <Navbar/>
            <div className="contentContainer">
                <Outlet/>
            </div>
        </div>
    );
};

export default Home;


