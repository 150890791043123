import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Flex, Typography } from "antd";
import { Services } from "../_utils/services";

const Intro = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [introData, setIntro] = useState<any>(null);

    const _onStartClick = (segment: string) => {
        navigate(`../quotient/${segment}`);
    }

    useEffect(() => {
        Services.getFile('intro').then((intro: any) => {
            setIntro(intro);
        })
    },[]);

    

    return (
        <>
            {/* {
                introData && segments.map((seg: any) => (
                    <CardComponent key={seg} card={introData[seg]} onClick={() => _onStartClick(seg)}/>
                ))
            } */}
            {
                introData && id && introData[id] && 
                    <div className="introWrap maxHeight scrollableDiv">
                        <CardComponent card={introData[id]} onClick={() => _onStartClick(id)}/>
                    </div>
                
            }
        </>
    )
}

export default Intro;


const CardComponent = ({ card, onClick }: any) => {

    const _gotolink = (link: string) => () => {
        window.open(`${link}`, '_blank');
    }
    return (
        <Card className="cardStyle appPadding">
            <Flex className="appPadding" vertical align="center" justify="space-between">
                <Typography.Title level={3} className="sitelink" onClick={_gotolink('https://kanishkabhattacharya.com')}>{'kanishkabhattacharya.com'}</Typography.Title>
                <Typography.Title level={2}>
                    {card.title}
                </Typography.Title>
                <div style={{margin: '2em 0', width: '100px', border: '2px solid #b5ea43'}}></div>
                <div style={{textAlign:'left'}}>
                    { card.descriptions.map((d: {text:string, highlights?: boolean}, idx: number) => (
                        <Typography.Title key={idx} level={5} style={{textAlign: 'justify'}}>
                            { d.highlights ? <b>{d.text}</b> : <>{d.text}</> }
                        </Typography.Title>
                    ))}
                </div>
                <div className="buttonstyle" style={{margin: '2em 0'}} onClick={onClick}>
                    Get Started
                </div>
            </Flex>
        </Card>
    )
}