

export const Services = {
    getFile : async (param: string) => {
        try {
            const response = await fetch(`/files/${param}.json`);
            const data = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            return null;
        }
    },

    getGradeSummaryFromScore : (score: number, gradeRules: any) => {
        return gradeRules.breakdown.find((rule: any) => score >= rule.score[0] && score <= rule.score[1]);
    }
}