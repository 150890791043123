import { useEffect, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import { Services } from "../_utils/services";
import { Button, Card, Col, ConfigProvider, Flex, Form, Layout, Radio, Row, Space, Steps, Tabs, Typography } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { useGlobalContext } from "../_utils/contextProvider";

// const data = {0: '11', 1: '9', 2: '11', 3: '11', 4: '11', 5: '9', 6: '11', 7: '11', 8: '11', 9: '11'};
// const data = null;
  
const layoutStyle = {
    overflow: 'hidden',
    width: '100%'
};
const contentStyle: React.CSSProperties = {
    padding: '3em',
    overflow: 'auto',
    backgroundColor: '#fff',
};
const siderStyle: React.CSSProperties = {
    backgroundColor: '#f0f0f0',
    padding: '3em',
    overflow:'auto',
};
const footerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#4096ff',
};




const Section = () => {
    const [form] = Form.useForm();
    const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
    const { calculateAndShowResults } = useGlobalContext();
    const { id } = useParams();
    const [questionaire, setQuestionaire] = useState<any>(null);
    const [quotientIntro, setQuotientIntro] = useState<any>(null);
    const [formData, setFormData] = useState<any>({});
    // const [submittedData, setSubmittedData] = useState<{[key: number]: number | string} | null>(null);
    const navigate = useNavigate();

    const _getQuestionaire = (id: string) => {
        Services.getFile('questions').then((data: any) => {
            setQuestionaire(data[id]);
        })
    }
    const _getIntro = (id: string) => {
        Services.getFile('intro').then((intro: any) => {
            setQuotientIntro(intro[id]);
        })
    }

    const _onFinish = (values: any) => {
        // let updatedData = null;
        // if (submittedData)
        //     updatedData = submittedData;
        // else {
        //     updatedData = values;
        // }
        let updatedData = values;
        setFormData(updatedData);
        console.log(updatedData);
        calculateAndShowResults(id, updatedData, questionaire.sections[0].questions, navigate);
    }

    useEffect(() => {
        _getQuestionaire(id as string);
        _getIntro(id as string);
    }, [id]);

    // useEffect(() => {
    //     if (data) {
    //         setSubmittedData(data);
    //         setFormData(data);
    //         // setComponentDisabled(true);
    //     }
    //     else {
    //         setSubmittedData(null);
    //         setComponentDisabled(false);
    //     }
    // }, [data])

    return (
        <>
            {
                questionaire && 
                <Layout style={layoutStyle} className="section appPadding">
                    <Sider width='35%' style={siderStyle} className="sectionSider">
                        <Typography.Title style={{margin: '1em 0'}} level={4}>{questionaire.header}</Typography.Title>
                        <Typography.Title style={{margin: '0 0 2em 0', color: 'red'}} level={5}>{questionaire.subheader}</Typography.Title>
                        { quotientIntro && quotientIntro.descriptions.map((d: {text:string, highlights?: boolean}, idx: number) => (
                            <div key={idx} style={{padding: '1em 0', textAlign: 'justify'}}>
                                { d.highlights ? <b>{d.text}</b> : <>{d.text}</> }
                            </div>
                        ))}
                    </Sider>
                    <Content style={contentStyle}>
                        <div className="sectionHeaderMobile">
                            <Typography.Title style={{margin: '1em 0 0'}} level={4}>{questionaire.header}</Typography.Title>
                            <Typography.Text style={{margin: '0 0 1em 0', color: 'red'}}>{questionaire.subheader}</Typography.Text>
                        </div>
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: '#b5ea43',
                                    fontSize: 12,
                                    colorBorder: '#d9d9d9',
                                },
                            }}
                            > 
                                <Form
                                    form={form}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    layout="vertical"
                                    onFinish={_onFinish}
                                    scrollToFirstError={true}
                                    disabled={componentDisabled}
                                    >
                                    {
                                        formData && <QuestionsList formData={formData} questions={questionaire.sections[0].questions} options={questionaire.options}/>
                                    }

                                    <Flex align="baseline" justify="flex-end" style={{margin: '2em 0'}}>
                                        <Form.Item >
                                            <Button type="primary" size="large" htmlType="submit">
                                                SUBMIT
                                            </Button>
                                        </Form.Item>
                                    </Flex>
                                </Form>
                        </ConfigProvider>
                    </Content>
                </Layout>
            }
        </>
        
    );
};

export default Section;


const QuestionsList = ({formData, questions, options}: any) => {

    const _getLabel = (question: string, count: string) => {
        return <Typography.Title level={5}>{count}. &nbsp;{question}</Typography.Title>;
    }

    return (
        
        <div>
            {
                questions.map((question: any, idx: number) => {
                    let key = idx;
                    return (
                        <Form.Item key={key} label={_getLabel(question.question, `${idx+1}`)} name={key} rules={[{ required: true, message: 'This is a required field!' }]}>
                        {/* <Form.Item key={key} label={_getLabel(question.question, `${idx+1}`)} name={key}> */}
                            <Space style={{paddingLeft: '2em'}}>
                                <Radio.Group defaultValue={parseInt(formData[key])} >
                                    <Space direction="vertical">
                                        { options.map((opt: any) => <Radio key={opt.id+idx} value={opt.value}>{opt.label}</Radio> ) }
                                    </Space>
                                </Radio.Group>
                            </Space>
                        </Form.Item>
                    )
                })
            }
        </div>
    );
};

