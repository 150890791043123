import { useNavigate, useParams } from "react-router-dom";

const Navbar = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const _gotoSection = () => {
        let segment = id === 'HQ' ? 'EQ' : 'HQ';
        navigate(`../quotient/${segment}`);
    }

    return (
        <div className="navbar appPadding">
            <div className="logowrap">
                <img src="/logo.png" alt="logo" onClick={() => navigate('/')}/>
            </div>
            <div className="navbar_right">
                {/* <span>Self Assessment</span> Zone */}
                <div className="buttonstyle" onClick={_gotoSection}> { id === 'EQ' ? 'Check your Happiness Quotient(HQ)' : 'Check your Emotional Intelligence(EQ)'}</div>
            </div>
        </div>
    )
}

export default Navbar;