import { BrowserRouter, Route, Routes, Navigate  } from 'react-router-dom';
import { Home, Section, Intro, Result } from './_pages';
import { GlobalContextProvider } from './_utils/contextProvider';

function App() {

  return (
    <BrowserRouter basename='/'>
      <GlobalContextProvider>
        <Routes>
          <Route path='/' element={<Home />} >
            <Route path='/' element={<Navigate to="/introduction/EQ" />} />
            <Route path='introduction/:id' element={<Intro />} />
            <Route path='quotient/:id' element={<Section />} />
            <Route path='results/:id' element={<Result />} />
          </Route>
        </Routes>
      </GlobalContextProvider>
    </BrowserRouter>
  );
}

export default App;
